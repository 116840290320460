:root {
    --brand-color: #fe7411;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-color: #dee2e6;
    --bs-border-radius: 0.375rem;
    --bs-body-color: #212529;
}

.app {
    /*background-color: var(--brand-color); /* Use the CSS variable for the brand color */
    background-image: url("/src/images/gradient.png");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin-top: 20px;
}

.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.card:hover {
    transform: translateY(-5px);
}

.card-thumbnail img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.card-content {
    padding: 16px;
}

.card-content h2 {
    font-size: 18px;
    margin: 0;
    margin-bottom: 8px;
}

.card-content p {
    font-size: 14px;
    color: #666;
    margin: 0;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
}

.primary-btn {
    margin-top: 10px;
    display: block;
    text-align: center;
    padding: 10px 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    border: 1px solid #fe7411;
    border-radius: 10px;
    background: #fe7411;
    transition: all 0.5s ease-in-out;
    color: #fff;
}

.secondary-btn {
    margin-top: 10px;
    display: block;
    text-align: center;
    padding: 10px 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    border: 1px solid #5542f6;
    border-radius: 10px;
    background: #5542f6;
    transition: all 0.5s ease-in-out;
    color: #fff;
}

.password-prompt {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.password-prompt input,
.password-prompt button {
    margin-top: 10px;
}

.password-prompt input {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    background-color: var(--bs-body-bg);
    border: 1px solid #dee2e6;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 0.375rem;
    border-radius: var(--bs-border-radius);
    color: #212529;
    color: var(--bs-body-color);
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
}

.logo {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
}
