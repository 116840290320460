.gallery-top {
    background-image: url("/src/images/gallery-hero.png");
    background-size: cover;
    width: 100%;
    height: 900px;
}

.gallery-top-sec {
    width: 75%;
    max-width: 620px;
    text-align: center;
    margin: 0 auto;
    padding: 60px 0;
}

.gallery-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 8px;
}

.gallery-details {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
    padding: 0 32px;
}

.gallery-details span {
    font-weight: 700;
}

.gallery-top-sec h2 {
    font-weight: 500;
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    line-height: 100%;
    text-align: center;
    color: #ffffff;
    margin-bottom: 30px;
}

.gallery-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    grid-gap: 32px;
    margin: 0 -60px;
    width: 100%;
}

.feature-card {
    padding: 32px 32px 16px 32px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    min-width: 286px;
    min-height: 380px;
    text-align: center;
}

.feature-card-img-wrapper {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feature-card-title {
    margin-top: 16px;
    height: 58px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: #272727;
}

.feature-card-details {
    margin: 0;
    margin-top: 8px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #272727;
}

.feature-card-highlight {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: var(--brand-color);
    margin-top: 8px;
}

.top-align {
    text-align: -webkit-center;
}

.cover-top {
    margin-top: -50px;
    margin-bottom: 40px;
    text-align: center;
    max-width: 500px;
}

.cover-top h2 {
    font-size: 56px;
    font-weight: 500;
    color: #fff;
    padding-top: 100px;
}

.cover-top p {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
}

.frm {
    margin-top: 80px;
    padding: 40px;
    background: #fff;
    border-radius: 8px;
    width: 500px;
    max-height: 600px;
    padding-left: 20px;
    padding-right: 50px;
}

.frm input {
    border: 1px solid #ced4da;
    border-radius: 8px;
    color: #212529;
    font-size: 1rem;
    padding: 8px 16px;
    width: 100%;
}

.frm input.email {
    margin-top: 20px;
}

.frm button {
    background: #fe7411 !important;
    border: 1px solid #fe7411 !important;
    color: #fff;
    border-radius: 8px;
    font-size: 20px !important;
    padding: 14px !important;
    transition: all 0.5s ease-in-out !important;
    margin-top: 1.5rem !important;
}

@media (max-width: 1920px) {
    .gallery-top {
        height: 550px;
    }
}

@media (max-width: 1800px) {
    .gallery-top {
        height: 550px;
    }
}

@media (max-width: 1600px) {
    .gallery-top {
        height: 630px;
    }
}

@media (max-width: 1440px) {
    .gallery-top {
        height: 630px;
    }
}

@media (max-width: 1200px) {
    .gallery-top {
        height: 600px;
    }

    .gallery-list {
        margin: auto;
    }

    .gallery-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 992px) {
    .gallery-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    .gallery-list {
        grid-template-columns: repeat(1, 1fr);
    }
}
